.btn {
    color: white;
    background-color: #367FFF;
    border-radius: 10px;
    padding: 5px;
    border: 0;
}

.btn:hover {
    background-color: #1f5dc8;
    color: white;
}

.main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    flex: 1;
    flex-grow: 1;
}

.my-form {
    width: 40%;
    padding: 0;
}

.form-input {
    border: 0;
    height: 30px;
    border-bottom: 1px solid grey;
    background-color: transparent;
    border-radius: 0;
}

.form-input:focus {
    outline: none !important;
    text-decoration: none;

}

.form-btn {
    padding: 5px;
    color: white;
    background-color: #1f5dc8;
    border: 0;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 10px;
}

.my-container {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    flex-grow: 1;
    margin: 0;
    min-width: 100%;
    width: 100%;
    min-height: 100vh;
    margin-top: 2.6rem;
    padding-left: 20px;
    position: "relative"
}

.side-bar {
    width: 250px;
    background-color: #F6F9FF;
    height: 100vh;
    margin-left: 5px;
    box-shadow: -5px 0 10px -5px rgba(0, 0, 0, 0.5),
        /* Left shadow */
        5px 0 10px -5px rgba(0, 0, 0, 0.5);
}

/* styles for subheader  */
.sub-header {
    display: flex;
    height: 50px;
    width: 99%;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    padding-right: 30px;
}

.sub-header-icon {
    height: 30px;
    width: 30px;
}

.sub-header-icon button {
    margin: 0 10px;
} 

.single-section-active {
    background-color: #EDEDED;
    border-left: 2px solid #367FFF;
    border-radius: 2px;
}

.section-name {
    padding: 5px;

}

.section-icon {
    background-color: red;
    color: white;
    height: 80%;
    width: 20px;
    padding: 3px;
    border-radius: 5px;
    margin-right: 4px;
}

.questions-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.question-container {
    padding: 1px;
    margin-left: 15px;
}

.question-icon {
    background-color: green;
    color: white;
    height: 80%;
    width: 15px;
    padding: 2px;
    border-radius: 5px;
    margin-right: 4px;
}

.single-question-active {
    border-left: 2px solid #367FFF;
    border-radius: 2px;
}

/* responsive  */
@media (max-width: 1024px) {
    .main-container {
        flex-direction: column;
        align-items: center;
    }

    .my-form {
        width: 80%;
    }

}

.placeholder-image {
    width: 229px;
    height: 229px;
    display: flex;
    flex-shrink: 1;
    align-self: center;
}

.parent-container {
    width: 100%;
}

.parent-container>div {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start
}