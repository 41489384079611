.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-left: 0px;
}

.pagination-item {
  display: inline-block;
  padding: 8px;
  margin: 2px;
  border-radius: 4px;
  background-color: #E4E4E4;
  cursor: pointer;
}
.pagination-item.active {
  background-color: #00A0E9;
  color: #fff;
}
.pagination-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.pagination-item a {
  color: inherit;
  text-decoration: none;
}
.create_form_card {
  width: 250px;
  height: 200px;
  
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 2px solid #7248b9;
}
.create_form_icons{
  cursor: pointer;
    transition: transform 0.2s, color 0.2s;
   
}
.create_form_icons:hover{
  transform: scale(1.2);

}

.create_form_card:hover {
  outline: 2px solid #7248b9;
}

.form_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}

.form_card {
  border: 2px solid #e2e2e2 !important;
  cursor: pointer;
  width: 208px;

}

.form_card:hover {
  outline: 2px solid #7248b9;
}

.form_card_img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  border-bottom: 1px solid #e2e2e2;
}

.form_card_img {
  width: 100%;
}

@media (max-width: 768px) {
  .form_grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-item {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.pagination-item.active {
  background-color: #ccc;
  color: white;
}

.page-link {
  color: #7349bd;
}

.page-link:hover {
  color: #7349bd;
}

.page-link.active:hover {
  color: white;
}

.page-link.active {
  border-color: #7349bd;
  background-color: #7349bd;
}

.page-link:focus {
  box-shadow: none;
  outline: none;
}


body {
  /* background-color: #fafbfc; */
}

.workspace_heading {
  font-size: 1.45rem;
  font-weight: 700;
  display: inline-block;
  padding: 5px 10px;
}

.headingandbutton_div {
  display: flex;
  justify-content: space-between;
}

.body_main {
  /* background-color: #ffffff; */
  display: flex;
  padding: 35px 30px;
}

.board {
  position: sticky;
  top: 80px;
  flex: 0.2;
  border-radius: 10px;
  height: fit-content;
}

.board__top>img {
  margin-bottom: -20px;
  width: 100%;
  height: 60px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.board__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  padding-bottom: 10px;
}

.board__topAvatar {
  font-size: 40px;
}

.board__stats {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.board__stat {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.board__stat>p {
  color: gray;
  font-size: 13px;
  font-weight: 600;
}

.board__statNumber {
  font-weight: bold;
  color: #0a66c2 !important;
}

.board__top>h4 {
  color: gray;
  font-size: 12px;
  margin-top: 5px;
}

.board__top>h2 {
  font-size: 18px;
}

.board__bottom {
  text-align: left;
  padding: 10px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
}

.board__recentItem {
  display: flex;
  font-size: 13px;
  color: gray;
  font-weight: bolder;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
}

.board__recentItem:hover {
  background-color: whitesmoke;
  border-radius: 10px;
  cursor: pointer;
  color: black;
}

.board__hash {
  margin-right: 10px;
  margin-left: 10px;
}

.board__bottom>p {
  font-size: 14px;
  padding-bottom: 10px;
}

/* Tabs container */
.userboard {
  margin-top: 80px;
}

.table_body {
  cursor: pointer;
  height: 60px;
}

.tab-container {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  margin-top: 10px;
}

.ellipsis_td {
  border-radius: 100%;
}

.ellipsis_td:hover {
  border-radius: 50%;
  background-color: #d6dbded9;
}

.tab {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  color: #949494;
  margin: 0px 10px;
}

.tab.active {
  border-bottom: 3px solid #0079bf;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  color: #0079bf;
  font-weight: 500;
}

.no-border {
  border: none;
}

.table_heading {
  color: #949494;
}

.table_row {
  height: 50px;
}

.table_row td {
  vertical-align: middle;
}

.tab-content {
  border-radius: 0 0 5px 5px;
}

.addworkspace_btn {
  border: none;
  background-color: #0a66c2;
  color: white;
  border-radius: 5px;
  margin-right: 10px;
  width: 150px;
  height: 40px;
  font-size: 15px;
}

.tabsand_btn {
  display: flex;
  justify-content: space-around;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.table_row {
  height: 50px;
}

.card-container {
  width: 200px;
  height: 200px;
  background-color: red;
  position: absolute;
}

.table_popup {
  border-radius: 5px;
  transition: 2ms ease;
  position: absolute;
  right: 110px;
  z-index: 1;
  background-color: white !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.274) !important;
  padding: 5px 0;
}

.table_popup li {
  padding: 5px 20px;
  text-align: start;
}

.table_popup li:hover {
  background-color: rgba(211, 211, 211, 0.288);
}

.disable_Star_Option {
  color: #999;
  background-color: #f7f7f7;
  cursor: not-allowed;
  pointer-events: none;
}

@media only screen and (max-device-width: 767px) {
  .board {
    display: none;
  }

  .tab {
    padding: 0px;
    margin: 0px;
    font-size: 9px;
    margin: 7px;
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    table-layout: fixed;
  }

  .table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .addworkspace_btn {
    border-radius: 5px;
    width: 112px;
    height: 35px;
    font-size: 12px;
  }

  .tab-container {
    display: inline-flex;
    margin-bottom: 10px;
    width: 100%;
  }

  .table_heading {
    font-size: 12px;
  }

  .table_row td {
    font-size: 12px;
  }

  .pagination {
    position: relative;
    display: flex;
    justify-content: center;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .pagination button {
    background-color: #e8e9eb;
    color: black;
    margin: 0px 2px;
    padding: 5px 10px;
    border-radius: 5px;
    position: relative;
    bottom: 10;
    border: 1px solid #e8e9eb;
  }

  .table_popup {
    position: absolute;
    right: 20px;
    z-index: 1;
    background-color: white !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.274) !important;
    padding: 5px 0;
  }

  .workspace_heading {
    font-size: 15px;
    margin-left: 15px;
  }
}

.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.pagination button {
  background-color: #e8e9eb;
  color: black;
  margin: 0px 2px;
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;
  bottom: 10;
  border: 1px solid #e8e9eb;
}

.pagination button:hover {
  background-color: #0079bf;
  color: white;
}

/* Feed */
.feed {
  flex: 0.6;
  margin: 0 20px;
}

.feed__inputContainer {
  /* padding: 15px 10px;  */
  border-radius: 10px;
  margin-bottom: 20px;
}

.feed__input {
  border: 1px solid lightgray;
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: gray;
  padding-left: 15px;
}

.feed__input>form {
  display: flex;
  width: 100%;
}

.feed__input>form>input {
  border: none;
  flex: 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
}

.feed__input>form>button {
  display: none;
}

.feed__inputOptions {
  display: flex;
  justify-content: space-evenly;
}

.inputOption {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: gray;
  padding: 10px;
  cursor: pointer;
}

.inputOption:hover {
  background-color: whitesmoke;
  border-radius: 10px;
}

/* Post */
.post {
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.post__header {
  display: flex;
  margin-bottom: 10px;
}

.post__info {
  margin-left: 10px;
}

.post__info>p {
  font-size: 12px;
  color: gray;
}

.post__info>h2 {
  font-size: 15px;
}

/* widgets */
.widgets {
  position: sticky;
  top: 80px;
  flex: 0.2;
  border-radius: 10px;
  height: fit-content;
  padding-bottom: 10px;
}

.widgets__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.widgets__header>h2 {
  font-size: 16px;
  font-weight: 400;
}

.widgets__article {
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.widgets__article:hover {
  background-color: whitesmoke;
}

.widgets__articleLeft {
  color: #0177b7;
  margin-right: 5px;
}

.cardtitle {
  font-size: 14px;
  text-align: center;
  margin-top: 7px;

  font-weight: normal;
}

.card-title {
  width: 130px;
  overflow: hidden;
  margin: 10px;
  overflow: hidden;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.widgets__articleLeft .material-icons {
  font-size: 15px;
}

.widgets__articleRight {
  flex: 1;
}

.widgets__articleRight>h4 {
  font-size: 14px;
}

.widgets__articleRight>p {
  font-size: 12px;
  color: gray;
}

.user__card {
  cursor: pointer;
  margin: 0 2% 2% 0;
  padding: 0;
  transform: translate(0);
  width: 23.5%;
}

.user__card_body {
  display: flex;
  flex-direction: column;
  height: 73px;
  justify-content: space-between;
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.start_hide {
  display: none;
}

.icon-star:hover .start_hide {
  display: block;
}

@media only screen and (max-device-width: 767px) {
  .widgets {
    display: none;
  }
}

.body__main {
  display: flex;
  padding: 0px 0px;
}

.board-box {
  width: 30px;
  height: 27px;
  display: inline-block;
  background-color: #ff850a;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
}

.box-right {
  font-size: 1rem;
  vertical-align: middle;
}

.box-right h4 {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 700;
}

.box-right p {
  font-size: 0.7rem;
  line-height: 1.5;
  margin-bottom: 0;
  max-width: 270px;
}

.Listeboard {
  display: block;
  height: 40px;
}

.Listeboard a {
  color: #5b5d5f;
  text-decoration: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.card-box-shadow {
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.card--shadow {
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
  padding: 10px 20px;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.circle {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background: #a3a3a3;
  margin-right: 15px;
}

.badge {
  height: 100px;
  width: 100px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: yellow;
}

.card-bg-img {
  background-image: url("https://trello-backgrounds.s3.amazonaws.com/SharedBackground/256x144/7152eef4906bc6d2bdbee613eef98bad/photo-1668423389856-2a82e96ee01b.jpg");
}

.btn-outline-secondary {
  border: 1px solid #cacbcc;
}

.btn:hover {
  background-color: #fff;
  border-color: #cacbcc;
}

.ul__a {
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  box-shadow: none;
  margin: 0;
  min-height: 20px;
  overflow: hidden;
  padding: 6px 8px 6px 0 !important;
  text-decoration: none;
}

.ul__icon {
  flex: 0 0 auto;
  text-align: center;
  width: 35px;
  color: hsl(218, 25%, 35%);
  font-weight: 500;
}

.ul__text {
  color: #6a6464;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ===============End BoardHome page Css ================ */

/* =============== Board page Css ================ */
.board_work_title {
  color: #5e6c84;
  align-items: center;
  flex: 1;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  margin: 20px 0;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alignleft {
  float: left;
  width: 542px;
  max-height: 44px;
  display: inline-table;
  margin-left: -8px;
}

.alignright {
  float: right;
}

.card_color_1 {
  background-color: rgb(10, 94, 10) !important;
  color: #fff;
}

.card_color_2 {
  background-color: rgba(13, 110, 253) !important;
  color: #fff;
}

.card_color_3 {
  background-color: rgb(140, 69, 19) !important;
  color: #fff;
}

.card_color_4 {
  background-color: rgb(193, 94, 24) !important;
  color: #fff;
}

.card-footer {
  border-top: none !important;
}

.boards--section {
  background-color: #091e420a;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  display: inline-block;
  margin-bottom: 8px;
  margin-left: 8px;
  padding: 6px 12px 6px 6px;
  position: relative;
  text-decoration: none;
}

/* .boards--section :hover{
  display: none;
} */
.boards-header-icon {
  border-radius: 3px;
  height: 32px;
  overflow: hidden;
  width: 32px;
  display: inline-flex;
  margin-left: 9px;
}

.Char_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  /* background-image: linear-gradient(#cb6924, #cc5f24); */
}

.upgrade_bg {
  background-color: #eddbf4;
  color: #586b95;
}

.upgrade_icon {
  background-color: #eddbf4;
}

.bodymain .home-boardheader {
  background-color: #ffffff;
  width: 100%;
  margin-top: 20px;
}

.app {
  /* display: flex; */
  flex-direction: column;
  height: 100vh;
  bottom: 0;
  left: 0px;
  right: 0;
  top: 0;
  position: absolute;
}

.logo {
  width: 55px;
  height: 48px;
}

.sidebar-open {
  margin-left: 250px;
  /* margin-left: 53px; */
  transition: 0.3s ease-in-out;
  box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.3);
}

.sidebar-close {
  margin-left: 53px;
  /* margin-left: 250px; */

  transition: 0.3s ease-in-out;
}

.header {
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.sidebar-toggle {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.content-wrapper {
  flex-grow: 1;
  transition: 0.3s ease-in-out;
  margin-left: 260px;
}

.content-wrapper-newuser {
  flex-grow: 1;
  transition: 0.6s ease-in-out;
  margin-top: 50px;
  margin-left: 45px;
}

.main-content {
  flex-grow: 1;
  padding: 0px;
  /* margin-top: -18px; */
  min-height: 100%;
  padding-top: 20px;
  margin-left: 0px;
  background-color: #F7FAFF;
}

.main-content2 {
  flex-grow: 1;
  padding: 0px;
  margin-top: 53px;
  height: 73vh;
  margin-left: 0px;
  /* background-color: #F7FAFF; */
}

@media only screen and (max-device-width: 890px) {
  .main-content {
    height: 82vh;
  }
}

/*.............................. Mobile Responsive.............................. */

@media (max-width: 500px) {
  .sidebar-close {
    margin-left: 12px;
  }

  .content-wrapper {
    flex-grow: 1;
    transition: margin-left 0.3s ease-in-out;
    margin-left: 20px;
  }
}

@media (min-width: 767px) and (max-width: 800px) {
  .content-wrapper {
    flex-grow: 1;
    transition: margin-left 0.3s ease-in-out;
    margin-left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .content-wrapper {
    flex-grow: 1;
    transition: margin-left 0.3s ease-in-out;
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .boards-header-icon {
    margin-left: 5px;
  }

  .user__card {
    margin-bottom: 8px;
    margin-right: 0%;
    width: calc(50% - 4px);
  }

  .col-6 {
    padding-right: 1rem;
  }

  .feed {
    margin: 0 5px;
  }

  .doted_icon {
    text-align: center;
  }
}