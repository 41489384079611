/* Add this CSS in your stylesheet or component styles */
.service-link {
  text-align: center;
  text-decoration: none;
  color: #333; /* Adjust the color as needed */
  transition: all 0.3s ease-in-out;
}

.service-link:hover {
  transform: translateY(-3px);
}

.service-icon {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.service-name {
  margin-top: 5px;
  font-size: 14px;
}

.form-logo {
  max-width: 100%;
}

/* You can customize these styles further based on your design preferences */
