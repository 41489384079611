.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.workspace-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.tick {
  font-size: 4em;
  color: #4caf50; /* Green color */
}

.popup-text {
  font-size: 1.5em;
  margin-top: 10px;
}

.close-icon {
  background: none;
  border: none;
  color: #4caf50; /* Green color */
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
