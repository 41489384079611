.graph_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 40px;
}

.graph_container > div {
  width: 100%;
}

.graphs {
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
}

@media screen and (max-width: 1024px) {
  .graph_container {
    grid-template-columns: 1fr;
  }
}
