.formImage {
  width: 74%;
}
.preview_main {
  min-height: 100vh;
}
.preview_container {
  /* max-width: 700px; */
  margin-bottom: 20px;
  margin-top: -110px;
  padding: 10px 0px;
  width: 100%;
}
.preview_header {
  /* margin-bottom: 15px; */
  /* border-top: 10px solid #673ab7; */
  background: linear-gradient(90deg, #516890 0%, rgba(54, 127, 255, 0) 100%);
  border-radius: 0px;
  width: 100%;
  color: white;
  padding-left: 20%;
  border: 0;
}

.buttonNextPrev {
  word-break: break-all;
  background-color: #fff;
  border-radius: 5px;
  flex-shrink: 0;
  border: 1px solid #367fff;
  color: #367fff;
  display: flex;
  justify-content: center;
  color: #367fff;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  gap: 7px;
}

.invalidText {
  font-size: 0.875em;
  color: #dc3545;
}

.requiredPoint {
  color: #f00;
  font-family: Open Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.buttonNextPrev:hover {
  border: 1px solid #367fff !important;
  color: #367fff !important;
}

.preview_title {
  /* border: 1px solid red; */
  height: auto;
  width: 100%;
  /* overflow: visible; */
  white-space: normal;
  padding: 0;
  margin-left: 0;
}

.preview_title h2 {
  max-width: 80%;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  font-size: 30px; /* Adjust the font size as needed */
  white-space: normal;
  margin-left: 0;
  padding: 0;
  width: 60%;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.preview_desc {
  margin-left: 0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  width: 60%;
}

.formSection {
  width: 58%;
  margin: auto;
  /* border: 1px solid red; */
}

.question_card {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid rgb(182, 182, 182);
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.preview_Question_image {
  width: 100%;
  height: 10rem;
  margin: auto;
  object-fit: contain;
  margin: 0.5rem 0rem;
}

.textBox {
  /* border: 1px solid red; */
}

.text_input {
  height: auto;
  border: none;
  outline: none;
  border-radius: 0px;
  border-bottom: 1px solid #e0e0e0;
  transition: border-bottom 0.3s ease;
  margin-left: 2%;
}
.text_input:focus {
  outline: none;
  border-bottom: 1px solid #673ab7;
}
/* .short_text_input {
  width: 300px;
} */
.indicates {
  background: transparent;
  color: red;
}
.customize_theme {
  width: 100%;
  height: 209px;
  margin-left: 2px;
  border-radius: 9px;
  margin-bottom: 14px;
}
.question_card {
  padding: 20px;
  margin-bottom: 15px;
  height: auto;
}
.date_input {
  width: 180px;
}
.required {
  color: red;
}
.time_input {
  width: 180px;
}
.dropdown option:hover {
  background-color: #673ab7;
  color: #ffffff;
}
.navigationButtons {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.dateCard,
.ratingCard,
.timeCard,
.documentUpload,
.phoneCard,
.dropDown {
  width: 90%;
  margin: auto;
  padding: 30px 0;
}

.inputFileContainer {
  position: relative;
  width: 392px;
  height: 176px;
  border-radius: 5px;
  background: #fff;
  box-shadow: -2px 2px 20px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.uploadedPreview {
  height: inherit;
  /* width: inherit; */
}

.inputFile {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.inputFile:disabled {
  cursor: not-allowed;
  opacity: 0;
}

.ratingCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headerCards {
  width: 90%;
  margin: auto;
}

.cardChange {
  min-height: 212px;
  padding-top: 5%;
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
}

.section_indicator {
  color: black;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  background: linear-gradient(90deg, #516890 0%, rgba(54, 127, 255, 0) 100%);
  text-transform: capitalize;
  transition: all 1s ease;
}

.section_indicator h4 {
  width: 60%;
  margin: auto;
}

.section_indicator h6 {
  width: 80%;
  margin: auto;
  font-weight: lighter;
}

.timer_div {
  background-color: #fff;
  color: white;
  padding: 0.5rem;
  position: fixed;
  bottom: 0;
  color: #516891;
  right: 0;
  border-radius: 5px;
  border: 1px solid #516891;
  margin: 2rem;
  font: 500;
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (max-width: 435px) {
  .preview_title h1 {
    font-size: 23px; /* Adjust the font size as needed */
    white-space: normal; /* Allow the text to wrap to the next line */
  }
}

@media (max-width: 784px) {
  .formSection {
    width: 95%;
    margin: auto;
  }
}
