.sidebar {
  position: fixed;
  top: 50px;
  left: 0px;
  height: 100%;
  /* background-color: rgba(211, 211, 211, 0.904); */
  background-color: white;
  z-index: 10;
  border-right: 1px solid #bbb;
}

.sidebaropen_icon {
  margin-left: 45px;
  margin-top: 3px;
  width: 28px;
  height: 28px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 13px;
  color: black;
}

.sidebaropenicon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;
  padding: 2px;
  margin-right: 5px;
  margin-top: 22px;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0px;
  top: 4px;
  color: black;
  font-size: 20px;
  z-index: 1;
}

.sidebaropenicon:hover {
  color: #172b4d;
}

.sidebaropen_icon:hover {
  background-color: rgba(164, 161, 161, 0.313);
}

.sidebarclose_icon {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}

.sidebaricon_onisPath {
  margin-left: 17px;
  cursor: pointer;
}

.sidebarclose_icon:hover {
  transition: left 0.5s ease;
  background-color: rgba(164, 161, 161, 0.313);
}

.sidebarcaret_icon {
  font-size: 20px;
  margin-left: 0px;
}

.sidebarcaretclose_icon {
  font-size: 20px;
  margin-left: 15px;
}

.sidebar.open {
  left: 0px;
  width: 250px;
  transition: 100ms ease;
}

.sidebar.closed {
  width: 53px;
  transition: 0.3s ease-in-out;
}

.sidebarcloses_icon {
  font-size: 20px;
  margin-left: 15px;
}

.sidebar_ul {
  /* padding: 10px 15px 10px 20px; */
  padding: 0px;
  margin-top: 20px;
}

.workspace_content {
  display: inline-flex;
  align-items: center;
}

.workspacename_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin-left: 17px;
}

.workspacename_icononclose {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 35px;
}

.workspaceName_navLink {
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.workspacename {
  margin-left: 10px;
  font-weight: 700;
  font-size: 1rem;
  width: 200px;
  text-transform: capitalize;
}

.horizontalline_onopen {
  width: 250px;
  margin-top: 7px;
  border: none;
  border: 1px solid #ffffff59;
  margin-bottom: 0px;
  margin-bottom: 7px;
}

.horizontalline_onclose {
  width: 53px;
  margin-top: 7px;
  border: none;
  border: 2px solid black;
  margin-bottom: 0px;
  margin-bottom: 7px;
}

.board_navlink {
  text-decoration: none;
}

.sidebar_li {
  padding: 6px 0px;
  margin-bottom: 8px;
}

.sidebar_li:hover {
  background-color: rgba(214, 216, 216, 0.457);
}

.active_navlink {
  /* background-color: hsla(0, 0%, 0%, 0.16); */
  padding: 6px 0px;
  color: darkblue !important;
  background-color: rgba(0, 0, 0, 0.16);
}
.sidebar_heading {
  display: flex;
  align-items: center;
  margin-left: 17px;
  font-size: 20px;
  position: relative;
  gap: 15px;
  border-bottom: 1px solid grey;
  font-size: 1rem;
  width: 70%;
  font-weight: 500;
  padding: 6px;
  padding-left: 0;
  padding-bottom: 10px;
}

.heading_boardPage {
  font-size: 17px;
  margin-left: 0px;
  font-size: 17px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 2px;
}

.sidebar_icon {
  font-size: 17px;
  margin-left: -8px;
}

.yourboard_icon {
  font-size: 17px;
  margin-left: 2px;
}

.yourboard_plusicon {
  margin-left: 20px;
  cursor: pointer;
}

.yourboards_div {
  width: 100px;
}

.yourboards_heading {
  margin-top: 0px;
  margin-left: 5px;
  font-size: 17px;
  font-weight: 500;
  width: 200px;
}

.plus_icon {
}

.allboards_div {
  left: 8px;
  cursor: pointer;
}

.board_div {
  cursor: pointer;
  display: inline-flex;
  margin-left: 10px;
}

.board_icon {
  width: 30px;
  height: 30px;
  margin-top: 0px;
  border: solid 1px;
  border-radius: 6px;
  font-size: 15px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.boardclose_icon {
  width: 30px;
  height: 30px;
  margin-top: 0px;
  margin-left: -10px;
  border: solid 1px;
  border-radius: 6px;
  font-size: 15px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.inline_container {
  display: inline-flex;
  gap: 10px;
  margin: 8px 0px;
}

.board_name {
  font-size: 16px;
  width: 150px;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 3px;
}

.board_ellipsisicon {
  display: flex;
  align-items: center;
}

.boardscroll_div {
  overflow-x: scroll;
  height: 510px;
  width: 230px;
}

.boardscrollclose_div {
  margin-left: 8px;
}

/*.............................. Mobile Responsive.............................. */

@media (max-width: 468px) {
  .sidebar_heading {
    font-size: 15px;
    position: relative;
  }

  .close_icon__sidebar {
    margin-left: 12.5px;
  }

  .sidebarheading_bottomline {
    display: block;
    position: relative;
    top: -8px;
  }

  .sidebar {
    position: fixed;
    top: 50px;
    left: 0px;
    height: 100%;
    background-color: white;
    transition: 0.6s ease-in-out;
    z-index: 10;
    box-shadow: 0.1px 0.1px #edebeb;
  }

  .sidebar.open {
    left: 0px;
    width: 250px;
    transition: 0.6s step-start;
  }

  .sidebar.closed {
    width: 53px;
    transition: 0.3s ease-in-out;
  }

  /* 


  .sidebar.open {
    left: 0px;
    width: 250px;
    transition: 0.2s ease-in-out;
  }


  .sidebar.closed {
    width: 70px;

    transition: 0.3s ease-in-out;

  } */

  .toggleOpen_Sidebar {
    width: 20px;
    height: 20px;
    display: flex;
    padding: 15px;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    position: relative;
  }

  .toggleClose_Sidebar {
    width: 20px;
    height: 20px;
    display: flex;
    padding: 14px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

/* Responsive layout for screens between 800px and 767px */
@media (min-width: 767px) and (max-width: 800px) {
  .sidebar_heading {
    display: block;
    font-size: 20px;
    position: relative;
  }

  .sidebarheading_bottomline {
    display: block;
    position: relative;
    top: -8px;
  }

  .sidebar {
    position: fixed;
    top: 50px;
    left: 0px;
    height: 100%;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    box-shadow: 0.1px 0.1px #edebeb;
  }

  /* 
  .sidebar.open {
    left: 0px;
    width: 250px;
    transition: 0.2s ease-in-out;
  }


  .sidebar.closed {
    width: 50px;

    transition: 0.3s ease-in-out;

  } */

  .sidebar.open {
    left: 0px;
    width: 250px;
    transition: 0.6s step-start;
  }

  .sidebar.closed {
    width: 53px;
    transition: 0.3s ease-in-out;
  }

  .toggleOpen_Sidebar {
    width: 20px;
    height: 20px;
    display: flex;
    padding: 15px;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    position: relative;
  }

  .toggleClose_Sidebar {
    width: 20px;
    height: 20px;
    display: flex;
    padding: 14px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

/*.............................. Ipad Responsive.............................. */

@media (min-width: 768px) and (max-width: 1024px) {
  .sidebar_heading {
    display: block;
    font-size: 20px;
    position: relative;
  }

  .sidebarheading_bottomline {
    display: block;
    position: relative;
    top: -8px;
  }

  .sidebar {
    position: fixed;
    top: 50px;
    left: 0px;
    height: 100%;
    /* background-color: lightgray; */
    background-color: white;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    box-shadow: 0.1px 0.1px #edebeb;
  }

  .sidebar.open {
    left: 0px;
    width: 250px;
    transition: 0.2s ease-in-out;
  }

  .sidebar.closed {
    width: 53px;

    transition: 0.3s ease-in-out;
  }

  .toggleOpen_Sidebar {
    width: 20px;
    height: 20px;
    display: flex;
    padding: 15px;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    position: relative;
  }

  .toggleClose_Sidebar {
    width: 20px;
    height: 20px;
    display: flex;
    padding: 14px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

@media screen and (max-width: 500px) {
  .sidebar.closed {
    width: 12px;
  }

  .sidebarcaretclose_icon {
    display: none;
  }

  .sidebaricon_onisPath {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin: 15px 0 0 2px;
    display: flex;
    background-color: #00000029;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    border: 1px solid var(--dynamic-text-transparent, #dfe1e6);
  }

  .sidebarcaret_icons {
    margin-left: 0;
    font-size: inherit;
  }

  .boardclose_icon {
    display: none;
  }

  .workspacename_icononclose {
    display: none;
  }

  .horizontalline_onclose {
    width: 0;
  }

  .sidebarclose_icon {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    left: -2px;
    margin: 15px 0 0 2px;
    display: flex;
    background-color: #00000029;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    border: 1px solid var(--dynamic-text-transparent, #dfe1e6);
  }
}
