/* Modal styles */
.workspace-modal .modal-header {
    border: none;
  }
  
  .workspace-modal .modal-header .mainheading_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 1rem;
  }
  .workspace-modal{
    padding-left: 1rem;
  }
  .workspace-modal .modal-header h4 {
    font-weight: bold;
  }
  
  .workspace-modal .modal-header .fa-xmark {
    cursor: pointer;
  }
  
  .workspace-modal .modal-body .main_para {
    color: #777;
  }
  
  .workspace-modal .modal-body h6 {
    font-size: 16px;
    margin-top: 1rem;
  }
  .modal-body{
    margin: 1rem;
    margin-top: 0;
    padding-top: 0;
  }
  
  .workspace-modal .modal-body h6 span {
    font-size: 14px;
    color: #777;
    margin-left: 0.5rem;
  }
  
  .workspace-modal .modal-body .workspacename_input,
  .workspace-modal .modal-body .workspace_desc {
    border: none;
    width: 100%;
    background-color: #f8f8f8;
    padding: 8px 0;
    margin-bottom: 1rem;
  }
  
  .workspace-modal .modal-body .workspacename_input::placeholder,
  .workspace-modal .modal-body .workspace_desc::placeholder {
    color: #ccc;
  }
  
  .workspace-modal .modal-body .workspace_desc {
    resize: none;
  }
  
  .workspace-modal .modal-body .continue_btn {
    background-color: #0A66C2;
    color: #fff;
    border: none;
    width: fit-content;
    outline: none;
    cursor: pointer;

  }
  
  .btn_div{
    text-align: center !important;
  }
  .continue_btn {
    text-align: center;
  }
  .workspace-modal .modal-body .continue_btn:hover {
    background-color: #0056b3;
  }
  
  .workspace-modal .modal-body .continue_btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .workspace_desc{
    box-shadow: 0 2px 4px #0000001a; 
    padding-left: 10px !important; 
    background-color: white;
    border: none;
    width: 100%; 
    resize: vertical;
  }
 .workspace_desc:focus {
  outline: none;
 }
  .workspacename_input:focus{
    outline: none;
  }
 .workspacename_input{
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  padding-left: 10px !important; 
  background-color: white;
  border: none;
  width: 100%; 
  resize: none;

 }
  .table{
  }