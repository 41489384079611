.workspaceName {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 800;
}

.nav-link {
  text-decoration: none !important;
}

.navbar_nav {
  border-bottom: 1px solid rgb(218, 220, 224);
}
.nav_main {
  display: "flex";
  flex-wrap: "wrap";
  height: "auto";
  height: 125px;
  display: inline-block;
}
.header_div_left {
  display: flex;
  align-items: center;
}
.forms_logo {
  width: 120px;
  margin-bottom: 3px;
}
.form_nav_name {
  margin: 0px;
  margin-top: -9px;
  /* width: 100px; */
}
.secflex {
  margin-left: auto;
}

.placeholder-input::placeholder {
  font-size: 16px;
  /* Set your desired font size */
}

.nav_title_input {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s;
  font-size: 16px;
}

.custom-link {
  text-decoration: none;
}

.nav_title_input:focus {
  border-bottom: 1px solid #673ab7;
}
.Container-Fluid {
  display: flex;
  align-items: center;
}

.nav_icons {
  color: #212529;
  cursor: pointer;
  font-size: 24px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: flex-end;
}

.middle-nav {
  align-items: flex-end;
  margin-bottom: 2px;
}

.middle-nav .nav_mid {
  margin-right: 10px;
}

.middle-nav .nav-link {
  text-decoration: none;
}
.middle-nav .navbar-nav {
  justify-content: center;
  align-items: center;
}
.middle-nav .nav-link.active {
  text-decoration: none;
  color: #000; /* Change the color as desired */
}

.nav_mid {
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
}
/* 
  .middle-nav {
    align-items: flex-end;
  } */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dropdown::before {
  max-width: 0px;
}

/* for grid icon css */
.dropdown_toggle:after {
  display: none !important;
  border-top: 0.3em solid transparent !important;
}
.middle-nav {
  align-items: flex-end;
  margin-bottom: 10px;
}

.middle-nav .nav_mid {
  margin-right: 10px;
}

.nav-icons {
  margin-left: 5px;
}

.small_button {
  margin-left: 5px;
}

.hidden_arrow {
  border: none;
  background-color: transparent;
}

.switch_to {
  border: none;
  box-shadow:
    0 4px 16px rgba(17, 17, 26, 0.1),
    0 8px 32px rgba(17, 17, 26, 0.05);
  margin-top: 10px !important;
  padding: 20px !important;
  width: 300px;
}

.dropdown-item {
  align-items: center !important;
  background-color: initial;
  border: 0;
  clear: both;
  color: #36315d;
  display: flex !important;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0 !important;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  text-decoration-line: none;
  /* text-decoration: none; */
}

/* .dropdown_menu{
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(0px, 24.8px, 0px);
    text-decoration: none;
    border: none;
    box-shadow: 0 4px 16px rgba(17, 17, 26, 0.1),
      0 8px 32px rgba(17, 17, 26, 0.05);
    margin-top: 10px !important;
    padding: 20px !important;
    width: 300px;
  }
   */

.navbar-nav .dropdown_menu {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 24.8px, 0px);
  text-decoration: none;
}
.switch_dots {
  align-items: center;
  background: #d9d9d9;
  border-radius: 50%;
  display: flex;
  height: 32px;
  margin: auto 10px auto 0;
  width: 32px;
}

.switch_to h6 {
  font-size: 1.5rem;
  font-weight: 600;
}

.dropdown_link {
  text-decoration: none;
}
/* .navbar-links{
    display: flex !important;
  } */

.text_circle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: #000;
}
@media screen and (max-width: 900px) {
  .nav_icons {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* .dropdown-menu {
      left: -141px !important;
      overflow-y: scroll;
    } */
}

@media screen and (min-width: 992px) {
  .fm {
    margin-left: 300px !important;
  }
}
@media screen and (min-width: 220px) and (max-width: 768px) {
  .header_div_left {
    flex-direction: column;
    align-items: flex-start;
    width: 35%;
  }
  .header_right {
    display: flex;
    flex-direction: row;
    width: 65%;
    margin-bottom: 20px;
    justify-content: end;
  }
  .forms_logo {
    width: 80px;
  }
  .header_div__middle {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    position: absolute;
    top: 70%;
    bottom: 0;
  }
  .header_div_center {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .mr {
    margin-right: 20px;
  }
  .small__button {
    padding: 2px 4px;
    font-size: 13px;
    height: 28px;
    width: 53px;
  }
  .nav_icons {
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .nav_vh {
    height: 125px;
    display: inline-block;
  }
  .root-col {
    margin-top: 21px;
  }
  .form_nav_name {
    margin-top: 0px;
  }
}

@media screen and (min-width: 665px) and (max-width: 1024px) {
  .header_right {
    display: flex;
    flex-direction: row;
    width: 35%;
    margin-bottom: 20px;
    justify-content: end;
  }
  .forms_logo {
    width: 80px;
  }
  .header_div__middle {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    position: absolute;
    top: 70%;
    bottom: 0;
  }
  .header_div_center {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .mr {
    margin-right: 20px;
  }
  .small__button {
    padding: 2px 4px;
    font-size: 13px;
    height: 28px;
    width: 53px;
  }
  .nav_icons {
    font-size: 22px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .nav_vh {
    height: 125px;
    display: inline-block;
  }
  .root-col {
    /* margin-top: 50px; */
  }
  .form_nav_name {
    margin-top: 0px;
  }
}
