.nav-link {
  text-decoration: none !important;
}
.forms_logo {
  width: 120px;
  margin-bottom: 3px;
}

.nav_main{
  display:"flex";
  flex-wrap:"wrap";
  height:"auto",

}
.form_nav_name {
  margin: 0px;
  /* margin-top: -9px; */
  /* width: 100px; */
}
.secflex{
  margin-left: auto;
}

.placeholder-input::placeholder {
  font-size: 16px;
  /* Set your desired font size */
}

.nav_title_input {
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s;
  width: fit-content;
  width: 240px;
  font-size: 10px;
}

.custom-link {
  text-decoration: none;
}

.nav_title_input:focus {
  border-bottom: 1px solid #673ab7;
}
.Container-Fluid {
  display: flex;
  align-items: center;
}

.nav_icons {
  color: #212529;
  cursor: pointer;
  font-size: 24px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: flex-end;
}

.middle-nav {
  align-items: flex-end;
  margin-bottom: 2px;
}

.middle-nav .nav_mid {
  margin-right: 10px;
}

.middle-nav .nav-link {
  text-decoration: none;
}
.middle-nav .navbar-nav {
  justify-content: center;
  align-items: center;
}
.middle-nav .nav-link.active {
  text-decoration: none;
  color: #000; /* Change the color as desired */
}

.nav_mid {
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
}
/* 
.middle-nav {
  align-items: flex-end;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dropdown::before {
  max-width: 0px;
}

/* for grid icon css */
.dropdown-toggle:after {
  display: none !important;
  border-top: 0.3em solid transparent !important;
}
.middle-nav {
  align-items: flex-end;
  margin-bottom: 10px;
}

.middle-nav .nav_mid {
  margin-right: 10px;
}

.nav-icons {
  margin-left: 5px;
}

.small-button {
  margin-left: 5px;
}

.hidden-arrow {
  border: none;
  background-color: transparent;
}


.switch-to {
  border: none;
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.1),
    0 8px 32px rgba(17, 17, 26, 0.05);
  margin-top: 10px !important;
  padding: 20px !important;
  width: 300px;
}

.dropdown-item {
  align-items: center !important;
  background-color: initial;
  border: 0;
  clear: both;
  color: #36315d;
  display: flex !important;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0 !important;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  text-decoration-line: none;
  /* text-decoration: none; */
}

.navbar-nav .dropdown-menu {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 24.8px, 0px);
  text-decoration: none;
}

.switch-dots {
  align-items: center;
  background: #d9d9d9;
  border-radius: 50%;
  display: flex;
  height: 32px;
  margin: auto 10px auto 0;
  width: 32px;
}

.switch-to h6 {
  font-size: 1.5rem;
  font-weight: 600;
}

.dropdown-link {
  text-decoration: none;
}
/* .navbar-links{
  display: flex !important;
} */

.Text__circle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: #000;
}
@media screen and (max-width: 900px) {
  .nav_icons {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /* .dropdown-menu {
    left: -141px !important;
    overflow-y: scroll;
  } */
}


 @media screen and (min-width: 992px) {
  .fm{
    margin-left:300px !important;;
   }
  
}