.boards_div {
  margin-top: 90px;
  margin-left: 10px;
}

.popup_main_container {
  width: 40%;
}

@media screen and (max-width : 680px) and (min-width : 378px) {
  .popup_main_container {
    width: 70%;
  }
}

@media screen and (max-width : 377px) and (min-width : 250px) {
  .popup_main_container {
    width: 90%;
  }
}

.addboard_btn {
  border: none;
  background-color: #0a66c2;
  color: white;
  border-radius: 5px;
  width: 120px;
  height: 30px;
  font-size: 15px;
}

.popup_divider {
  border: 0.7px solid #606060;
  width: 90%;
  margin-bottom: 10px;
}

.table_body td {
  vertical-align: middle;
}

.popup_heading {
  font-weight: bold;
  font-size: 1.5em;
  margin-left: 10px;
}

.buttongrid_div {
  display: inline-flex;
  align-items: center;
}

.headingandbutton_div {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
}

.invitebg {
  background-color: #fff;
  /* background-color: #fafbfc; */
}

.invite__container {
  display: flex !important;
  justify-content: space-around !important;
}

.invite__rows {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-header {
  display: flex;
  align-items: center;
  position: relative;
  top: -35px;
  right: 60px;
  flex-direction: column;
  align-items: flex-start;
}

.invite-header-icon {
  display: flex;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 60px;
  width: 60px;
  cursor: default;
  position: relative;
}

.invite_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.invitbtn {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow:
    rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0,
    rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition:
    all 0.2s,
    box-shadow 0.08s ease-in;
  user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.invitbtn:disabled {
  cursor: default;
}

.invitbtn:focus {
  box-shadow:
    rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0,
    rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

.model-header {
  display: flex;
  align-items: center;
}

.model-header-icon {
  display: flex;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  cursor: default;
  position: relative;
}

.model_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  background: #ebecf0;
}

.model__link a {
  border: none;
  box-shadow: none;
  background-color: transparent;
  text-decoration: underline;
  color: #5e6c84;
}

.drop__down {
  align-items: center;
  border-radius: 3px;
  cursor: default;
  background-color: #eeeeee;
  display: flex;
  min-height: 36px;
  height: 36px;
  padding: 8px 12px;
}

.drop__down:hover {
  background-color: #eeeeee;
  color: black;
  text-decoration: none;
}

.dropdown_invit {
  text-decoration: none;
  color: black !important;
  padding: 8px 12px;
  min-height: 36px;
  height: 36px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.upgrade {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.upgrade_p {
  color: #172b4d;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 16px;
}

.upgrade_small {
  color: #172b4d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.upgrade_btn {
  color: #172b4d;
  background-color: #eddbf4;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 12px;
  margin-bottom: 15px;
}

.button-15 {
  background-color: #0b5ed7;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
}

.button-15:hover {
  background-color: #0e6af4;
  border: none;
  box-shadow: none;
  color: #fff;
}

.delete-main-col-wrapper {
  margin: 0 0;
  max-width: 850px;
  padding: 32px;
  position: relative;
}

.btn__icon_display {
  display: flex;
  flex-direction: row;
}

.mx0 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.workspace_content {
  display: inline-flex;
  margin-left: 10px;
}

.workspacename_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-top: 2px;
  margin-left: -10px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
}

.workspacename_icononclose {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-left: -14px;
  margin-top: 35px;
}

.workspaceName_navLink {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  margin-top: 6px;
}

.workspacename {
  margin-left: 10px;
  font-weight: 700;
  font-size: 17px;
  width: 200px;
}

.table_popup {
  border-radius: 5px;
  transition: 2ms ease;
  position: absolute;
  right: 70px;
  z-index: 1;
  background-color: white !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.274) !important;
}

.table_popup li {
  padding: 2px 20px;
  text-align: start;
}

.table_popup li:hover {
  background-color: rgba(211, 211, 211, 0.288);
}

.table_popup_board {
  border-radius: 5px;
  transition: 2ms ease;
  position: absolute;
  right: -130px;
  z-index: 1;
  background-color: white !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.274) !important;
  color: black;
  padding-left: 0rem;
  width: 12rem;
}

.table_popup_board li {
  margin: 0.4rem;
  padding: 2px 15px;
  text-align: start;
}

.table_popup_board li:hover {
  background-color: rgba(211, 211, 211, 0.288);
}

.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.pagination button {
  background-color: #e8e9eb;
  color: black;
  margin: 0px 2px;
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;
  bottom: 10;
  border: 1px solid #e8e9eb;
}

.pagination button:hover {
  background-color: #0079bf;
  color: white;
}

.toggleview_btn {
  margin: 0px 10px;
  cursor: pointer;
}

.grid_icon {
  font-size: 25px;
}

.table_icon {
  font-size: 25px;
}

.table_div {
  margin: 5px 20px;
}

.board-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.boardtextcss {
  font-size: 12px;
  color: #484545;
  font-weight: 600;
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.loading_container {
  text-align: center;
  width: 100%;
}

​ .lds_ripple {
  width: 80px;
  height: 80px;
}

.lds_ripple div {
  position: absolute;
  border: 4px solid rgb(0, 98, 161);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds_ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media only screen and (max-device-width: 768px) {
  .board {
    display: none;
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    table-layout: fixed;
  }

  .table_div {
    margin: 9px 0px;
  }

  .headingandbutton_div {
    margin: 0;
  }

  .table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tab {
    padding: 0px;
    margin: 0px;
    font-size: 11px;
    margin: 7px;
  }

  .workspacename {
    font-size: 17px;
  }

  .workspaceName_navLink {
    width: 90px;
  }

  .pagination {
    position: relative;
    display: flex;
    justify-content: center;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .pagination button {
    background-color: #e8e9eb;
    color: black;
    margin: 0px 2px;
    padding: 5px 10px;
    border-radius: 5px;
    position: relative;
    bottom: 10;
    border: 1px solid #e8e9eb;
  }

  .table_popup {
    position: absolute;
    right: 30px;
    z-index: 1;
    background-color: white !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.274) !important;
  }

  .table_popup_board {
    position: absolute;
    right: 30px;
    z-index: 1;
    background-color: white !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.274) !important;
  }

  .toggleview_btn {
    margin: 0px 10px;
    cursor: pointer;
  }

  .grid_icon {
    font-size: 20px;
  }

  .table_icon {
    font-size: 20px;
  }

  .addboard_btn {
    width: 85px;
    height: 28px;
    font-size: 10px;
    margin-right: 10px;
  }

  .invite-header {
    top: 0px;
    right: 100px;
  }

  .invite_icon {
    height: 45px;
    width: 45px;
    font-size: 20px;
  }

  .showing_text {
    margin-left: -22px;
  }

  .invite-header-icon {
    align-items: center;
    margin-right: 0;
  }

  .button-15 {
    font-size: 14px;
    padding: 9px 15px;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .btn__icon_display {
    display: block;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .invite_btnPosition {
    top: -103px;
    right: -82px;
    /* right: -82px; */
  }

  .mx0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .alignright {
    float: left;
  }
}

@media (max-width: 767px) {
  .headingandbutton_div {
    flex-wrap: wrap;
  }

  .card-modal-side-container {
    margin-top: 1rem;
    border-top: 2px solid gray;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
  }

  .table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .workspace_content {
    flex-wrap: wrap;
  }

  .buttongrid_div {
    flex-wrap: wrap;
  }

  .mx0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 490px) {
  .invite__container {
    display: block !important;
    justify-content: space-around !important;
  }

  .invite-header {
    top: 0px;
    right: 40px;
  }

  .invite_btnPosition {
    top: -100px;
    right: -80px;
  }

  .alignright {
    float: left;
  }

  .headingandbutton_div {
    flex-wrap: wrap;
  }
}
