.addCard_card {
    /* position: sticky;
    top: 35%;
    overflow: hidden; */
  }
.addCard_options {
    cursor: pointer;
    margin: 10px auto;
    align-items: center;
    margin-bottom: 0;
  }
  
  .addCard_icon {
    padding: 20px;
    height: 50px;
  }
  
  .sidebar {
    width: 250px;
  }
  
  .content {
    margin-left: 250px;
  }
  /* AddCardPage.css */
  .addCard_videoCard {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 600px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Shift the content to the left */
    justify-content: flex-start;
  }
  
  .addCard_videoCard .card-body {
    padding: 20px;
  }
  
  .addCard_videoCard .card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .videoCard_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .videoCard_logoImage {
    width: 40px;
    height: 40px;
    align-items: flex-start;
  }
  
  .videoCard_searchBox {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .videoCard_searchInput {
    width: 100%;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .videoCard_searchIcon {
    font-size: 14px;
    color: #555555;
    cursor: pointer;
    background: #dfdfdf;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 3px;
  }
  
  .videoCard_selectedVideo {
    margin-top: 10px;
    font-size: 14px;
    color: #555555;
  }
  
  .videoCard_buttons {
    display: flex;
    justify-content: flex-start;
    /* align-items:flex-end; */
    
    margin-top: auto;;
  }
  
  .videoCard_buttons button {
    margin-left: 10px;
  }
  
  