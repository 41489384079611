.preview_container {
  max-width: 700px;
  margin-bottom: 20px;
  margin-top: -90px;
}
.show_preview {
  top: -128px;
  position: relative;
}
.preview_header {
  margin-bottom: 25px;
  border-top: 10px solid #673ab7;
  border-radius: 8px;
}
.preview_title{
  overflow: visible;
  margin: 0px;
  height: auto;
}
.question_card {
  padding: 20px;
  margin-bottom: 15px;
  height: auto;
}

/* text */
.short_text_input {
  width: 300px;
}

.text_input {
  height: auto;
  border: none;
  outline: none;
  border-radius: 0px;
  border-bottom: 1px solid #e0e0e0;
  transition: border-bottom 0.3s ease;
}

.preview_Question_image {
  width: 100%;
  height: 10rem;
  object-fit: contain;
  margin: 0.5rem 0rem;
}

.form_control:focus {
  box-shadow: none;
  border-color: #e0e0e0;
}

.text_input:focus {
  outline: none;
  border-bottom: 1px solid #673ab7;
}

/* radio and checkbox */
.custom_control_input:checked ~ .custom-control-label::before {
  background-color: #673ab7;
  border-color: #673ab7;
}

.form_check_input:checked {
  background-color: #673ab7;
  border-color: #673ab7;
}

.form_check_input:focus {
  box-shadow: none;
  border-color: #e0e0e0;
}

.date_input {
  width: 150px;
}
.time_input {
  width: 130px;
}

/* .dropdown {
    min-width: 150px;
    width: 200px;
    max-width: 400px;
    border-radius: 3px;
  } */

/* dropdown */
.form_select:focus {
  box-shadow: none;
  border-color: #e0e0e0;
}

.dropdown option:hover {
  background-color: #673ab7;
  color: #ffffff;
}
.navigationButtons {
  display: flex;
  justify-content: space-between;
  width: auto;
}

.section_indicator {
  background-color: #673ab7;
  color: lightgray;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 10px 10px 0 0;
  text-transform: capitalize;
  transition: all 1s ease;
}
