.btn {
  border-radius: 4px;
  padding: 5px 10px;
  background-color: #673ab7;
  border-color: #673ab7;
  transition: background-color 0.3s ease;
}
.btn:hover {
  background-color: #7349bd;
  border-color: #7349bd;
}

.btn.disabled {
  background-color: #673ab7;
  border-color: #673ab7;
}
