.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding-left: 0px;
  }
  .pagination-item {
    display: inline-block;
    padding: 8px;
    margin: 2px;
    border-radius: 4px;
    background-color: #E4E4E4;
    cursor: pointer;
  }
  .pagination-item.active {
    background-color: #00A0E9;
    color: #fff;
  }
  .pagination-item.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .pagination-item a {
    color: inherit;
    text-decoration: none;
  }
  .create_form_card {
    width: 250px;
    height: 200px;
    
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid #7248b9;
  }
  .create_form_icons{
    font-size: 500%;
    color:#7349bd;
    cursor: pointer;
      transition: transform 0.2s, color 0.2s;
     
  }
  .create_form_icons:hover{
    transform: scale(1.2);
  
  }
  .all_form_text{
    font-size: 16px;
    font-weight: bold;

  }
  
  .create_form_card:hover {
    outline: 2px solid #7248b9;
  }
  
  .form_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
  }
  
  .form_card {
    border: 2px solid #e2e2e2 !important;
    cursor: pointer;
    width: 208px;
  
  }
  
  .form_card:hover {
    outline: 2px solid #7248b9;
  }
  
  .form_card_img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    border-bottom: 1px solid #e2e2e2;
  }
  
  .form_card_img {
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .form_grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-item {
    display: inline-block;
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .pagination-item.active {
    background-color: #ccc;
    color: white;
  }
  
  .page_link {
    color: #7349bd;
  }
  
  .page_link:hover {
    color: #7349bd;
  }
  
  .page_link.active:hover {
    color: white;
  }
  
  .page_link.active {
    border-color: #7349bd;
    background-color: #7349bd;
  }

  
  .page_link:focus {
    box-shadow: none;
    outline: none;
  }
  