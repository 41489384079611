.border {
  border-bottom: 1px solid black;
}
.workspace_challenge {
  margin-left: 23.060029282576867vw;
  margin-top: 4vh;
  display: flex;
  margin-right: 60px;
}
.workspaceChallenge__header {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin-top: 25px;

  /* margin-left: 50px; */
}
.postachallenge__button {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  margin-left: 50px;
  margin-bottom: 13px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #3e3e3e;
  background: #673ab7;
  border-radius: 30px;
  border: none;
}
.workspaceChallenge__headerText {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #c6c6c6;
}
.workspaceChallenge__main {
  width: 100%;
}
.workspaceChallenge__headerFilter {
  display: flex;
  align-items: center;
}
.workspaceChallenge__headerFilterText {
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}
.workspace_challenge__body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.workspace_challenge__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #7248b9;
  max-width: 360px;
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 10px;
  width: 250px;
  height: 200px;
  cursor: pointer;
  /* cursor: pointer; */
}
.workspace_challenge__card:hover {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.switch_dots_form {
  height: 36px;
  margin-top: -12px;
  width: 36px;
}
.workspaceChallenge__card:hover {
  border: 2px solid #7248b9;
}

.workspace_challenge__card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  margin-top: 20px;
}
.workspaceChallenge__cardMoreIcon {
  margin-bottom: 13px;
}
.workspace_challenge__card_header_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 197px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* display: flex;
    align-items: center; */
  color: #3e3e3e;
}
.workspace_challenge__card_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  max-width: 210px;
  margin-top: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* align-items: center; */
  color: #444444;
  /* overflow-x: "hidden";
    text-overflow:"ellipsis";
    white-space: "nowrap" */
}
.workspaceChallenge__cardMoreIcon {
  margin-right: 20px;
}
.postachallenge__button {
  margin-right: 20px;
}
.workspaceChallenge__cardView {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-top: 25px;
}
.workspaceChallenge__cardViewText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3b71ca;
}
/* .workspaceChallenge__cardviewdetail{
      width: 150px;
      position: absolute;
      height: 90px;
      background: white;
      border: 1px solid black;
      border-radius: 5px;
      margin-left: 52px;
      display: none;
  } */
.workspaceChallenge__cardviewdetailtext {
  margin-left: 10px;
  cursor: pointer;
}
.workspace_challenge__card_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.5px solid #e3e3e3;
}
.workspace_challenge__card_footer_left {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 15px;
}
.workspace_challenge__count {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: #444444;
}
.postachallenge__button {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  margin-left: 50px;
  margin-bottom: 13px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: white;
  background: #433a81;
  border-radius: 30px;
  border: none;
}
.svg_icons {
  cursor: pointer;
  margin-left: 50px;
}
@media only screen and (max-width: 875px) {
  .workspaceChallenge {
    margin-left: 100px;
  }
}
@media only screen and (max-width: 778px) {
  .workspaceChallenge {
    margin-left: 20px;
    margin-top: 4vh;
    margin-right: 5px;
  }
  .workspaceChallenge__headerText {
    margin-left: 1rem;
  }
  .challenge__modal {
    width: 90vw;
  }
  .workspaceChallenge__headerFilterText {
    margin-left: 1px;
  }
  .workspaceChallenge__body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 6rem;
    justify-content: center;
  }
  .workspaceChallenge__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #e3e3e3;
    /* max-width: 360px; */
    width: 90vw;
    border-radius: 10px;
    /* margin-right: 20px; */
    margin-top: 10px;
  }
}
@media only screen and (max-width: 478px) {
  .workspaceChallenge {
    margin-left: 0vw;
    margin-top: 4vh;
    margin-right: 5px;
  }
  .challenge__modal {
    width: 300px;
  }
  .workspaceChallenge__headerFilterText {
    margin-left: 1px;
  }
  .workspaceChallenge__body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: flex-end;
    align-items: center;
  }
  .workspaceChallenge__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #e3e3e3;
    /* max-width: 360px; */
    width: 90vw;
    border-radius: 10px;
    /* margin-right: 20px; */
    margin-top: 10px;
  }
}
