.user_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.user_name {
  margin-left: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.share_btn {
  cursor: pointer;
  background-color: #0b5ed7;
  color: white;
  width: 70px;
  margin-left: -20px;
}


.card_styling {
  width: 285px;
  white-space: normal;
}

.card_styling p {
  white-space: normal;
}