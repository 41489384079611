.response_main {
  margin-top: 3rem;
}
.titlepage_card_response {
  margin: auto;
  border: 0;
}
.container_response {
  margin-top: 154px;
}
.cursor {
  cursor: pointer;
}
.titlepagecard_span_response {
  background-color: #673ab7;
  height: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.export_button {
  padding: 0.5rem;
  text-decoration: "underline";
  color: "blue";
  cursor: pointer;
}
.export_button:hover {
  color: rgba(0, 0, 255, 0.69);
}

@media screen and (max-width: 420px) {
  .response_main {
    margin-top: 160px;
  }
}
