.root_wrapper {
  max-width: 1000px;
  margin: auto;
  display: grid;
  grid-template-columns: 15fr 1fr;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  /* margin-top: 200px; */
}
/* .crossIconParent{
    background: #9e9e9e;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 22px;
    justify-content: center;
    cursor: pointer;
    float: right;
    position: relative;
  } */
.crossIcon {
  background: #9e9e9e;
  border-radius: 50px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  float: right;
  padding: 4px;
  position: relative;
  margin-bottom: -15px;
  margin-right: -4px;
}
.root_col {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 50px;
}
.back_arrow {
  font-size: 28px;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 16px;
}
.root_col_2 {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  flex-direction: column;
  justify-content: flex-end;
}
.timer_btn {
  padding: 1rem 1.25rem;
  margin: 0.25rem;
  border-radius: 6px;
  background-color: #ffff;
  box-sizing: border-box;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.timer_icon {
  font-size: 1.5rem;
}
.add_button {
  background-color: rgb(138, 60, 179);
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;

  display: flex;
  justify-content: flex-start;
}
.add_button_wrapper {
  /* border: 1px solid red; */
  /* width: 70%; */
  display: flex;
  justify-content: center;
  margin: auto;
}

button:active {
  background-color: rgb(107, 5, 162) y;
}

button:focus {
  outline: none;
}

input[type="add-text"] {
  padding: 10px 20px;
  border: none;
  color: #f1f3f4;
  border-radius: 4px;
  background-color: rgb(138, 60, 179);
  font-size: 16px;
}

input[type="add-text"]:focus {
  outline: none;
}

.form-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #f1f3f4;
  font-family: Arial, sans-serif;
}

@media screen and (max-width: 768px) {
  .root_wrapper {
    display: unset;
  }

  .addCard_options {
    display: flex;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 420px) {
  .root_wrapper {
    margin-top: 260px !important;
  }
}
.form-container {
  position: relative;
}

.titlepagecard_span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  border-radius: 12px;
  background-color: rgb(5, 109, 244);
}

.questionpage_card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  border-radius: 2px;
  background-color: rgb(5, 109, 244);
}

.select_form.show-options {
  height: 300px;
}

.fa_copy,
.fa_trash {
  font-size: 20px;
  margin-right: 10px;
}

.fa_trash {
  margin-right: 20px;
}

.smallSwitch {
  transform: scale(1.5);
}

.form_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  height: 100px;
}

.form_description {
  font-size: 16px;
  margin-bottom: 20px;
  height: 150px;
}

.field_container {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 150px;
}

/* CustomAlert.css */

.Custom-alert {
  display: flex;
}
.Custom-alert p {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}
.Custom-alert span {
  color: #eeff41;
  font-weight: bold;
  margin-left: 16px;
  cursor: pointer;
}

.field-question {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.field-type {
  margin-bottom: 10px;
}

.field_options {
  margin-left: 20px;
  margin-bottom: 10px;
}

.option-input {
  margin-bottom: 5px;
}

.button_container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  /* background-color: #4285f4; */
  /* background-color: #f1f3f4; */
  color: #3c4043;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.customize_theme {
  width: 100%;
  height: 209px;
  margin-left: 2px;
  border-radius: 9px;
  margin-bottom: 14px;
}
.iframe {
  margin-left: 13px;
}
.button_secondary {
  background-color: #f1f3f4;
  color: #3c4043;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
}

.button_danger {
  background-color: #db4437;
}

.button:hover {
  opacity: 0.9;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.questionpage_container {
  margin-top: 20px;
}
.customize-theme {
  width: 100%;
  height: 209px;
  margin-left: 2px;
  border-radius: 9px;
  margin-bottom: 14px;
}

.questionpage_card {
  margin-top: 18px;
  margin-bottom: 20px;
}
.videopage_card {
  margin-top: 18px;
  margin-bottom: 20px;
}

.question_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 12pt;
  border-bottom: 2px solid white;
  width: 100%;
  height: auto;
}
.points_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 12pt;
  border-bottom: 2px solid rgb(101, 96, 96);
  width: 100%;
  height: auto;
}

.question_input:focus {
  border-bottom-color: #673ab7;
}
.qImgUrl_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qImgUrl {
  margin: 1rem 0rem;
  width: 100%;
  height: 10rem;
  object-fit: contain;
}
.removeQuestionImage {
  color: red;
  margin: 0rem 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.inputandSelect_container {
  display: flex;
  justify-content: space-between;
}
.inputandImg_container {
  width: -webkit-fill-available;
  /* border: 1px solid red; */
  margin-right: 1.5rem;
}

.select_form {
  width: 250px;
  height: 30px;
  margin: 20px 30px;
  border: 1px solid lightgray;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}
.image_question {
  margin: 16px 30px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 5px;
}
.select_image {
  margin: 16px 3px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 5px;
}

.multiplechoice_container {
  display: block;
  margin-left: 20px;
}

.multiplechoice_input {
  margin: 8px 5px;
  border: none;
  outline: none;
  font-weight: 500;
  width: 200px;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
}

.multiplechoice_span {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  width: 200px;
  font-size: 15px;
  cursor: pointer;
  display: block;
}

.checkbox_container {
  display: block;
  margin-left: 20px;
}

.checkbox_input {
  margin: 8px 5px;
  border: none;
  outline: none;
  font-weight: 500;
  width: 200px;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
}

.checkbox_span {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  width: 200px;
  font-size: 15px;
  cursor: pointer;
  display: block;
}

.shortanswer_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  width: 500px;
  font-size: 18px;
  cursor: pointer;
}

.paragraph_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  color: gray;
  font-size: 14px;
  cursor: pointer;
}
.text_area {
  resize: none;
}

.time_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  color: gray;
  width: 200px;
  font-size: 18px;
  cursor: pointer;
}

.fileupload_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  color: gray;
  width: 200px;
  font-size: 15px;
  cursor: pointer;
}

.parent {
  margin: 1rem;
  padding: 2rem 2rem;
  text-align: center;
}

.row {
  display: inline-block;
  margin-left: 0px;
  margin-bottom: 10px;
  padding: 1rem 1rem;
}

.column {
  display: inline-block;
  margin-left: 200px;
  margin-bottom: 10px;
  padding: 1rem 1rem;
}

.date_input {
  margin: 23px 23px;
  border: none;
  outline: none;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  color: gray;
  width: 200px;
  font-size: 18px;
  cursor: pointer;
}

.options_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  color: gray;
  width: 85%;
  font-size: 18px;
  cursor: pointer;
}

.condtional_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  color: gray;
  width: 100%;
  font-size: 18px;
  cursor: pointer;
}

.showconditional_options{
  display: flex;
  flex-wrap: wrap;
}

.smallSwitch {
  transform: scale(0.6);
  background: #673ab7;
}
.smallSwitchR {
  transform: scale(0.6);
}

.questioncard_options {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: end;
  margin: 0px 30px;
  cursor: pointer;
}

.required_span {
  padding: 0;
  margin: 0;
  font-size: 14px;
  margin-top: 4px;
}

.fa-tras:before {
  color: #db4437;
}
.section_indicator {
  background-color: #673ab7;
  color: lightgray;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 10px 10px 0 0;
  text-transform: capitalize;
  transition: all 1s ease;
}

.titlepagecard_span {
  background-color: #673ab7;
  height: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.title_input {
  margin: 13px 13px;
  border: none;
  outline: none;
  font-size: 24pt;
  font-weight: 500;
  border-bottom: 2px solid lightgray;
  transition: border-color 0.3s;
}

.title_input:focus {
  border-bottom-color: #673ab7;
}
.description_input {
  margin: 10px 13px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 11pt;
  border-bottom: 2px solid lightgray;
}

.description_input:focus {
  border-bottom-color: #673ab7;
}
.image_preview {
  width: 200px;
  height: auto;
  margin-top: 10px;
}
.add-btn {
  margin-top: 10px;
  width: 100px;
  height: 50px;
  border-radius: 12px;
  background-color: #673ab7;
  color: #f1f3f4;
}

.linear_scale_container {
  flex-direction: column;
  align-items: center;
}
.linear_scale_labels {
  justify-content: space-between;
  margin-bottom: 10px;
}
.linear_scale_input {
  align-items: center;
}

.dropdown_icon {
  position: relative;
  margin-left: 10px;
}

.dropdown_icon::after {
  content: "\25BE";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.linear_scale_select {
  appearance: none;
  padding: 5px 25px 5px 5px;
  font-size: 14px;
  border: none;
  background-color: transparent;
}
.linear_scale_range {
  width: 200px;
  margin: 0 10px;
}

.header_div_left {
  display: flex;
  align-items: center;
}
.header_div__middle {
  display: flex;
  width: 100%;
}
.header_div_center {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
.small__button {
  padding: 5px 10px;
  font-size: 14px;
  height: 30px;
  width: 70px;
}
@media screen and (min-width: 220px) and (max-width: 768px) {
  .header_div_left {
    flex-direction: column;
    align-items: flex-start;
    width: 35%;
  }
  .header_right {
    display: flex;
    flex-direction: row;
    width: 65%;
    margin-bottom: 20px;
    justify-content: end;
  }
  .forms_logo {
    width: 80px;
  }
  .header_div__middle {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    position: absolute;
    top: 70%;
    bottom: 0;
  }

  .header_div_center {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .mr {
    margin-right: 20px;
  }
  .small__button {
    padding: 2px 4px;
    font-size: 13px;
    height: 28px;
    width: 53px;
  }
  .nav_icons {
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .nav_vh {
    height: 125px;
    display: inline-block;
  }
  .root-col {
    margin-top: 21px;
  }
  .form_nav_name {
    margin-top: 0px;
  }
}

@media screen and (min-width: 665px) and (max-width: 1024px) {
  .header_right {
    display: flex;
    flex-direction: row;
    width: 35%;
    margin-bottom: 20px;
    justify-content: end;
  }
  .forms_logo {
    width: 80px;
  }
  .header_div__middle {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    position: absolute;
    top: 70%;
    bottom: 0;
  }
  .header_div_center {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .mr {
    margin-right: 20px;
  }
  .small__button {
    padding: 2px 4px;
    font-size: 13px;
    height: 28px;
    width: 53px;
  }
  .nav_icons {
    font-size: 22px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .nav_vh {
    height: 125px;
    display: inline-block;
  }
  .root-col {
    /* margin-top: 50px; */
  }
  .form_nav_name {
    margin-top: 0px;
  }
}
.section_delete_button {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.section_header_bar {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: #673ab7;
}
.timer_div {
  background-color: #673ab7;
  color: white;
  border-radius: 1rem;
  padding: 0.25rem;
  font: 500;
}

@media screen and (max-width: 490px) {
  .root_col {
    margin-top: 120px;
  }
  .root_col_2 {
    margin-top: 10px;
  }
}
