@import url("https://fonts.googleapis.com/css2?family=Google+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  padding: 0;
  margin-top: 100px;
  /* background-color: #f0ebf8; */
  font-family: "Google Sans", Roboto, Arial, sans-serif;
}

.btn:hover {
  background-color: #673ab7;
}
