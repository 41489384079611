.editworkspace_card {
    width: 300px;
    border: 0px;
    margin: 0px 80px;
}


.workspace_inputs:hover {
    background-color: #efefefc4;
}

.save_btn {
    background-color: #095bd6;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
}

.save_btn:hover {
    background-color: #0055cc;
}

.cancel_btn {
    background-color: #efefef93;
    color: black;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0px 10px;

}

.cancel_btn:hover {
    background-color: #efefefc4;
}