.container_width {
  max-width: 770px;
}
.response_container {
  width: 100%;
}
.card_design {
  padding: 20px 24px;
}
.titlepage_card_response {
  margin: auto;
  border: 0;
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  margin-top: 0;
  page-break-inside: avoid;
}
.titlepagecard_span_response {
  background-color: #673ab7;
  height: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.option {
  padding: 3px 10px;
  margin: 5px 0;
}
.correct_option {
  background-color: #00800066;
  border-radius: 4px;
  padding: 3px 10px;
  margin: 5px 0;
}

.incorrect_option {
  border-radius: 4px;
  padding: 3px 10px;
  background-color: #ff000091;
  margin: 5px 0;
}
.titlepagecard_span_response::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  border-radius: 12px;
  background-color: rgb(5, 109, 244);
}
.questionpage_container_response {
  margin-top: 20px;
}

.question_input_response {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0;
  border: none;
  outline: none;
  width: 100%;
  height: auto;
}

.questionpage_card_response {
  margin-top: 18px;
  margin-bottom: 20px;
}
.questionpage_card_response::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  border-radius: 2px;
  background-color: rgb(5, 109, 244);
}
.text_message {
  word-break: keep-all;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: rgb(32, 33, 36);
  background-color: rgb(248, 249, 250);
  border-radius: 4px;
  margin: 4px 0 0;
  padding: 10px;
}
.qImgUrl_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qImgUrl {
  margin: 1rem 0rem;
  width: 100%;
  height: 10rem;
  object-fit: contain;
}
